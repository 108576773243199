import React from "react";
import {
  BiBook,
  BiLock,
  BiPaperPlane,
  BiSolidHand,
  BiUser,
} from "react-icons/bi";
import OurMissionCard from "./OurMissionCard";

const OurMission = () => {
  return (
    <div className="overflow-hidden bg-blue-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Join Us In
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                OUR MISSION
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We ensure top security and transparency, protecting user assets
                and trust. Customer satisfaction is our priority, with tailored
                services for diverse needs. Our user-friendly solutions make
                gold investment accessible to all. We deliver sustainable
                long-term value, fostering trust through responsible practices
                and performance, while educating on gold investment benefits.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <OurMissionCard
                  title="Security"
                  text="Ensure the highest standards of security and transparency in
                    all transactions, safeguarding the assets and trust of our
                    users."
                  icon={BiLock}
                />

                <OurMissionCard
                  title="Customer-Centricity"
                  text="Prioritize customer satisfaction and feedback, tailoring our services to meet the evolving needs and preferences of our diverse user base."
                  icon={BiUser}
                />

                <OurMissionCard
                  title="Accessibility"
                  text="Make gold investment accessible to everyone regardless of geographical location, financial status, or technical expertise through user-friendly digital solutions."
                  icon={BiSolidHand}
                />

                <OurMissionCard
                  title="Long-Term Value"
                  text="Focus on delivering sustainable long-term value for our stakeholders, fostering trust and loyalty through responsible business practices and consistent performance."
                  icon={BiPaperPlane}
                />

                <OurMissionCard
                  title="Education"
                  text="Educate and empower individuals about the benefits and opportunities of investing in gold, fostering financial literacy and wealth preservation."
                  icon={BiBook}
                />
              </dl>
            </div>
          </div>
          <img
            src="https://cdn.pixabay.com/photo/2015/10/30/10/03/gold-1013618_1280.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-4 ring-yellow-100 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width="2432"
            height="1442"
          />
        </div>
      </div>
    </div>
  );
};

export default OurMission;
