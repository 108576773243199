import React from "react";

const CompanyGlance = () => {
  return (
    <div className="section_padding px-12 bg-yellow-50" id="company_at_glance">
      <div className="flex">
        <div className="w-1/2">
          <h2 className="text-3xl font-bold uppercase">
            <span className="text-yellow-500">COMPANY</span> AT A GLANCE
          </h2>
          <div className="text-justify leading-6">
            <p className="italic text-yellow-700 font-semibold">
              <q>
                Swimmduck Private Limited is a trusted platform offering digital
                gold investment services, catering to the diverse needs of
                investors across India with operates the swissgold.app platform
                which integrates the entire lifecycle of Gold for a one-stop
                solution for our customers.
              </q>
            </p>
            <p className="font-semibold">
              Offer customers the ability to buy, sell, and manage gold
              digitally, eliminating the need for physical transactions. The
              security measures in place to protect customers' digital gold
              holdings, such as encryption, multi-factor authentication, and
              insurance coverage. Audience by allowing customers to start with
              small denominations and offering various payment options,
              including bank transfers, credit/debit cards, and digital wallets.
              Provide transparent pricing and fees, along with real-time market
              data, so customers can make informed decisions about their
              investments. Ensure customers have easy access to their funds by
              offering quick and seamless withdrawal options. Highlight the
              ability to convert digital gold into fiat currency or other assets
              with minimal hassle. Educate customers about the benefits of
              including gold in their investment portfolios as a hedge against
              inflation and market volatility Position digital gold as a
              convenient way to diversify their investment holdings.
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <img
            src="./assets/images/hero.png"
            className="w-[60%] mx-auto"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyGlance;
