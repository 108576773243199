import React from "react";

const SectionHeader = ({ title, subtitle, titleClass = "text-slate-800" }) => {
  return (
    <div className="text-center mb-6">
      <h4 className={`sm:text-2xl text-lg font-semibold  mb-2 ${titleClass}`}>
        {title}
      </h4>
      <h1 className="md:text-3xl sm:2xl text-lg font-bold uppercase text-yellow-500">
        {subtitle}
      </h1>
    </div>
  );
};

export default SectionHeader;
