import React from "react";
import { LuArrowRight } from "react-icons/lu";

const FeaturedCard = ({ title, description, icon }) => {
  let Icon = icon;
  return (
    <div className="lg:w-[32%] w-full relative overflow-hidden shadow-lg rounded-lg bg-blue-900 p-8">
      <div className="">
        <div className="absolute -right-3 -top-3 bg-yellow-500 p-5 rounded-full">
          <Icon className="w-10 h-10 text-white" />
        </div>
        <a href="#">
          <h5 className="mb-2 text-2xl font-bold uppercase text-white">
            {title}
          </h5>
        </a>
        <p className="mb-3 font-normal text-white">{description}</p>
        <a
          href="#"
          className="inline-flex font-medium items-center text-gray-300"
        >
          Click for more info
          <LuArrowRight />
        </a>
      </div>
    </div>
  );
};

export default FeaturedCard;
