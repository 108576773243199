import PageHeader from "components/website/PageHeader/PageHeader";
import React from "react";

const VideoGallery = () => {
  return (
    <div>
      <PageHeader pageTitle="Video Gallery" />
      <div className="section_padding">
        <h4 className="text-center text-red-600 text-xl">
          No videos are available. We will update soon.
        </h4>
        {/* <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <iframe
              class="rounded-lg w-full h-[250px]"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VideoGallery;
