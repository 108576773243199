import React from "react";

const OurMissionCard = ({ title, text, icon = "" }) => {
  const Icon = icon;
  return (
    <div className="relative pl-9">
      <dt className="inline font-semibold text-gray-900">
        <Icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
        {title}
      </dt>
      <dd className="inline ms-2">{text}</dd>
    </div>
  );
};

export default OurMissionCard;
