import { BlogsComp } from "components";
import PageHeader from "components/website/PageHeader/PageHeader";
import React from "react";

const Blogs = () => {
  return (
    <>
      <PageHeader pageTitle="Blogs" />
      <BlogsComp />
    </>
  );
};

export default Blogs;
