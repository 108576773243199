import React from "react";
import { BiBuilding } from "react-icons/bi";

const OurVision = () => {
  return (
    <section className="bg-gray-900 px-6 py-12" id="our_vision">
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <div className="flex justify-center mb-[24px]">
          <div className="inline-flex w-18 h-18 p-5 bg-yellow-500 shadow rounded-full">
            <BiBuilding className="w-10 h-10 text-white" />
          </div>
        </div>
        <h3 className="text-center font-bold text-3xl text-yellow-500">
          <q>OUR VISION</q>
        </h3>
        <figure className="mt-8">
          <blockquote className="text-center text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
            <p>
              <q>
                To revolutionize the way people invest in and utilize gold by
                providing a seamless, secure, and accessible digital platform
                that empowers individuals worldwide to participate in the gold
                economy.
              </q>
            </p>
          </blockquote>
        </figure>
      </div>
    </section>
  );
};

export default OurVision;
