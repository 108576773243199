import React, { useState } from "react";
import { Accordion } from "flowbite-react";
import PageHeader from "components/website/PageHeader/PageHeader";
import { Tabs } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";

const text = `
SWIMMDUCK app is 100% safe and secure to use for your Daily Savings & Investments in Gold. It is powered by Augmont and all payments happen over secure banking networks.
`;

const Faq = () => {
  const [allFaqs, setAllFaqs] = useState([
    {
      key: "1",
      label: "Is SWIMMDUCK App safe to use?",
      content: <p>{text}</p>,
    },
    {
      key: "2",
      label: "Can I Earn Money from the SWIMMDUCK app?",
      content: <p>{text}</p>,
    },
    {
      key: "3",
      label: "What does the SWIMMDUCK App do?",
      content: <p>{text}</p>,
    },
    {
      key: "4",
      label: "How do I download the SWIMMDUCK App?",
      content: <p>{text}</p>,
    },
  ]);

  const [appFaqs, setAppFaqs] = useState([
    {
      key: "1",
      label: "What does the SWIMMDUCK App do?",
      content: <p>{text}</p>,
    },
    {
      key: "2",
      label: "How do I download the SWIMMDUCK App?",
      content: <p>{text}</p>,
    },
    {
      key: "3",
      label:
        "Is KYC required to Start Investing in Digital Gold using SWIMMDUCK App?",
      content: <p>{text}</p>,
    },
    {
      key: "4",
      label: "How do I download the SWIMMDUCK App?",
      content: <p>{text}</p>,
    },
  ]);

  const [digitalGoldFaqs, setDigitalGoldFaqs] = useState([
    {
      key: "1",
      label: "What is Digital Gold?",
      content: <p>{text}</p>,
    },
    {
      key: "2",
      label: "Is digital gold Real gold?",
      content: <p>{text}</p>,
    },
    {
      key: "3",
      label: "Where is my gold saved?",
      content: <p>{text}</p>,
    },
    {
      key: "4",
      label: "How do I convert my savings to physical gold?",
      content: <p>{text}</p>,
    },
  ]);

  const [withdrawalFaqs, setWithdrawalFaqs] = useState([
    {
      key: "1",
      label: "How to Stop/Pause Daily Savings?",
      content: <p>{text}</p>,
    },
    {
      key: "2",
      label: "How to Stop/Pause Round-Offs?",
      content: <p>{text}</p>,
    },
    {
      key: "3",
      label: "How to Buy Digital Gold?",
      content: <p>{text}</p>,
    },
    {
      key: "4",
      label: "How to withdraw your Savings from SWIMMDUCK App?",
      content: <p>{text}</p>,
    },
  ]);

  return (
    <div>
      <PageHeader pageTitle="All your questions, answered" />
      <div className="section_padding bg-blue-950">
        <div className="md:w-1/2 w-full mx-auto">
          {/* <h2 className="text-4xl font-bold uppercase mb-8 text-center">
            <span className="text-white">All your questions, </span>
            <span className="text-yellow-500">answered.</span>
          </h2> */}
          <Tabs aria-label="Default tabs" className="faq_tabs">
            <Tabs.Item active title="All FAQ's" icon={HiUserCircle}>
              <Accordion collapseAll>
                {allFaqs.length > 0 &&
                  allFaqs.map((faq, index) => {
                    return (
                      <Accordion.Panel
                        className="bg-blue-900 mb-4"
                        key={index + 1}
                      >
                        <Accordion.Title className="focus:outline-none focus:shadow-none focus:ring-0 bg-blue-900 text-white rounded-2xl mb-3">
                          {faq.label}
                        </Accordion.Title>
                        <Accordion.Content className="bg-blue-100 mb-3 text-blue-900 font-semibold rounded-2xl">
                          {faq.content}
                        </Accordion.Content>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion>
            </Tabs.Item>

            <Tabs.Item title="SwimDuck App" icon={MdDashboard}>
              <Accordion collapseAll>
                {appFaqs.length > 0 &&
                  appFaqs.map((faq, index) => {
                    return (
                      <Accordion.Panel
                        className="bg-blue-900 mb-4"
                        key={index + 1}
                      >
                        <Accordion.Title className="focus:outline-none focus:shadow-none focus:ring-0 bg-blue-900 text-white rounded-2xl mb-3">
                          {faq.label}
                        </Accordion.Title>
                        <Accordion.Content className="bg-blue-100 mb-3 text-blue-900 font-semibold rounded-2xl">
                          {faq.content}
                        </Accordion.Content>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion>
            </Tabs.Item>

            <Tabs.Item title="Digital Gold" icon={HiAdjustments}>
              <Accordion collapseAll>
                {digitalGoldFaqs.length > 0 &&
                  digitalGoldFaqs.map((faq, index) => {
                    return (
                      <Accordion.Panel
                        className="bg-blue-900 mb-4"
                        key={index + 1}
                      >
                        <Accordion.Title className="focus:outline-none focus:shadow-none focus:ring-0 bg-blue-900 text-white rounded-2xl mb-3">
                          {faq.label}
                        </Accordion.Title>
                        <Accordion.Content className="bg-blue-100 mb-3 text-blue-900 font-semibold rounded-2xl">
                          {faq.content}
                        </Accordion.Content>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion>
            </Tabs.Item>

            <Tabs.Item title="Withdrawal Issues" icon={HiClipboardList}>
              <Accordion collapseAll>
                {withdrawalFaqs.length > 0 &&
                  withdrawalFaqs.map((faq, index) => {
                    return (
                      <Accordion.Panel
                        className="bg-blue-900 mb-4"
                        key={index + 1}
                      >
                        <Accordion.Title className="focus:outline-none focus:shadow-none focus:ring-0 bg-blue-900 text-white rounded-2xl mb-3">
                          {faq.label}
                        </Accordion.Title>
                        <Accordion.Content className="bg-blue-100 mb-3 text-blue-900 font-semibold rounded-2xl">
                          {faq.content}
                        </Accordion.Content>
                      </Accordion.Panel>
                    );
                  })}
              </Accordion>
            </Tabs.Item>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Faq;
