import { CustomButton } from "components";
import React from "react";
import { LuSend } from "react-icons/lu";

const ChoosingCard = ({ title, subtitle, description, link = "/" }) => {
  return (
    <div className="p-6 rounded-lg bg-blue-900 text-white shadow mb-4">
      <h4 className="text-2xl font-bold uppercase">
        {title} <span className="text-yellow-500">{subtitle}</span>
      </h4>
      <p>{description}</p>
      <div>
        <CustomButton
          name="Learn More"
          type="btn-primary"
          icon={LuSend}
          link={link}
        />
      </div>
    </div>
  );
};

export default ChoosingCard;
