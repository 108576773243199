import React from "react";
import BlogComp from "./BlogComp";

const BlogsComp = () => {
  return (
    <div>
      <div className="bg-white py-14 sm:py-16 px-12">
        <h4 className="text-center text-red-600 text-xl">
          No content available. We will update soon.
        </h4>
        {/* <div className="grid xl:grid-cols-4 gap-x-6 gap-y-6">
          <BlogComp />
          <BlogComp />
          <BlogComp />
          <BlogComp />
          <BlogComp />
          <BlogComp />
          <BlogComp />
          <BlogComp />
        </div> */}
      </div>
    </div>
  );
};

export default BlogsComp;
