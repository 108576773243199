import React, { useState } from "react";
import { LuChevronDown, LuHome, LuMenu } from "react-icons/lu";
import { BiUser } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BASE_URL } from "utils/Constant";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="bg-yellow-100 sticky top-0 left-0 z-50 shadow header">
      <nav
        className="mx-auto flex  items-center justify-between py-5 px-12"
        aria-label="Global"
      >
        <div className="flex justify-start">
          <Link to="/" className="">
            <img
              className="sm:h-12 h-10 object-contain"
              src={`${BASE_URL}assets/images/logo-3.png`}
              alt=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden menu__buttoncol">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <LuMenu
              className="sm:w-10 sm:h-10 w-8 h-8"
              onClick={handleToggleMenu}
            />
          </button>
        </div>
        <div
          className={`lg:flex lg:flex-1 lg:justify-end header__menucol ${
            showMenu ? "header__menushow" : "header__hideshow"
          }`}
        >
          <ul className="flex items-center h_menu mb-0 ps-0 header__menu">
            <li className="h_nav_item">
              <Link to="/" className="h_nav_link">
                <LuHome className="me-1 menu__homeicon" /> Home
              </Link>
            </li>

            <li className="h_nav_item">
              <Link to="/about" className="h_nav_link">
                About Us <LuChevronDown />
              </Link>

              <ul className="h_submenu">
                <li className="h_sub_nav_item">
                  <Link to="/about#company_at_glance" className="h_nav_link">
                    Company at a glance
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <Link to="/about#what_is_this_app" className="h_nav_link">
                    What's this App
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <Link to="/about#managing_director" className="h_nav_link">
                    Managing Director
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <Link to="/about#teams" className="h_nav_link">
                    Team Members
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <Link to="/about#our_vision" className="h_nav_link">
                    Vision & Mission
                  </Link>
                </li>
              </ul>
            </li>

            <li className="h_nav_item">
              <a href="#" className="h_nav_link">
                Features <LuChevronDown />
              </a>

              <ul className="h_submenu">
                <li className="h_sub_nav_item">
                  <a
                    href="https://swissgold.app/secure/buyGold"
                    className="h_nav_link"
                  >
                    Buy Gold
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a
                    href="https://swissgold.app/secure/sellGold"
                    className="h_nav_link"
                  >
                    Sell Gold
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Digital Gold
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Daily Savings
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Round Off
                  </a>
                </li>
              </ul>
            </li>

            <li className="h_nav_item">
              <a href="#" className="h_nav_link">
                Resources <LuChevronDown />
              </a>

              <ul className="h_submenu">
                <li className="h_sub_nav_item">
                  <Link to="/blogs" className="h_nav_link">
                    Blog
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Insights
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Growth Calculator
                  </a>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    Retirement Calculator
                  </a>
                </li>
              </ul>
            </li>

            <li className="h_nav_item">
              <a href="#" className="h_nav_link">
                Gallery <LuChevronDown />
              </a>

              <ul className="h_submenu">
                <li className="h_sub_nav_item">
                  <Link to="gallery/images" className="h_nav_link">
                    Image
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <Link to="gallery/videos" className="h_nav_link">
                    Video
                  </Link>
                </li>
              </ul>
            </li>

            <li className="h_nav_item">
              <a href="#" className="h_nav_link">
                Help <LuChevronDown />
              </a>

              <ul className="h_submenu">
                <li className="h_sub_nav_item">
                  <Link to="/faq" className="h_nav_link">
                    FAQs
                  </Link>
                </li>
                <li className="h_sub_nav_item">
                  <a href="#" className="h_nav_link">
                    App How To’s
                  </a>
                </li>
              </ul>
            </li>

            <li className="h_nav_item">
              <Link to="/contact-us" className="h_nav_link">
                Contact Us
              </Link>
            </li>
          </ul>
          <a
            href="https://swissgold.app/secure/consumerLogin"
            className="btn btn-primary ms-2"
          >
            <BiUser className="btn-icon me-1" /> Log in
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
