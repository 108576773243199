import React from "react";
import { BiMobile } from "react-icons/bi";

const AboutApp = () => {
  return (
    <section className=" bg-blue-950 px-6 py-12" id="what_is_this_app">
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <div className="flex justify-center mb-[24px]">
          <div className="inline-flex w-18 h-18 p-5 bg-yellow-500 shadow rounded-full">
            <BiMobile className="w-10 h-10 text-white" />
          </div>
        </div>
        <h3 className="text-center font-bold text-3xl text-yellow-500">
          <q>WHAT IS THIS APP</q>
        </h3>
        <figure className="mt-8">
          <blockquote className="text-center text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
            <p>
              <q>
                The Digital Gold App is a leading player in the digital gold
                investment sector, known for its user-friendly interface, robust
                security measures, and transparent pricing. It has gained a
                reputation for making gold investment accessible to a broader
                audience, including retail investors.
              </q>
            </p>
          </blockquote>
        </figure>
      </div>
    </section>
  );
};

export default AboutApp;
