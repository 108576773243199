import React from "react";
// In your component file
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import WebsiteLayout from "./layouts/WebsiteLayout";
import { Homepage } from "./pages/website";
import About from "pages/website/About/About";
import ScrollToTop from "components/common/ScrollToTop";
import ImageGallery from "pages/website/Gallery/ImageGallery/ImageGallery";
import VideoGallery from "pages/website/Gallery/VideoGallery/VideoGallery";
import ContactUs from "pages/website/ContactUs/ContactUs";
import Faq from "pages/website/Faq/Faq";
import Blogs from "pages/website/Blog/Blogs";
import OurPolicy from "pages/website/OurPolicy/OurPolicy";
import TermsConditions from "pages/website/TermsConditions/TermsConditions";
import Disclaimer from "pages/website/Disclaimer/Disclaimer";

function RedirectToSecure() {
  const navigate = useNavigate();
  React.useEffect(() => {
    window.location.href = "https://swissgold.app/secure";
  }, [navigate]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<WebsiteLayout />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/gallery/images" element={<ImageGallery />} />
          <Route path="/gallery/videos" element={<VideoGallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/our-policy" element={<OurPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Route>

        <Route path="/secure" element={<RedirectToSecure />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
