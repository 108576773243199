import { CustomButton } from "components";
import SectionHeader from "components/website/SectionHeader/SectionHeader";
import React from "react";
import { LuSend } from "react-icons/lu";
import Slider from "react-slick";
import { BASE_URL } from "utils/Constant";

// Sample images array
const images = [
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
  `${BASE_URL}assets/images/no_image.png`,
];

const ImageGallery = () => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // XL screens
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992, // LG screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768, // MD screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576, // SM screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // XS screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="section_padding ">
      <div className="carousel-container">
        <SectionHeader
          title="Gallery"
          subtitle="Explore stunning visuals in our  gallery"
        />
        <Slider {...settings} className="m-2">
          {images.map((image, index) => (
            <div className="p-2">
              <div
                key={index}
                className="shadow border-2 border-gray-200 overflow-hidden flex items-center h-[250px]"
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full"
                />
              </div>
            </div>
          ))}
        </Slider>

        <div className="mt-10 text-center">
          <CustomButton
            type="btn-secondary btn-h-view-all"
            icon={LuSend}
            name="View All Images"
            link="/gallery/images"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
