import React from "react";
import { BiCopyright } from "react-icons/bi";
import {
  LuFacebook,
  LuInstagram,
  LuMap,
  LuMapPin,
  LuMessageSquare,
  LuPhone,
  LuTwitter,
  LuYoutube,
} from "react-icons/lu";
import { Link } from "react-router-dom";
import { BASE_URL } from "utils/Constant";

const Footer = () => {
  return (
    <footer className="bg-slate-950">
      <div className="mx-auto w-full  pb-6 pt-10 px-12">
        <div className="xl:flex xl:justify-between">
          <div className="mb-6 md:mb-0 text-white">
            <Link to="/" className="flex items-center">
              <img
                src={`${BASE_URL}assets/images/logo-3-white.png`}
                className="h-16 me-3 "
                alt="Logo"
              />
            </Link>
          </div>
          <div className="xl:mt-0 mt-10">
            <div className="flex md:flex-row flex-col gap-10">
              <div className="ps-1 text-white">
                <h2 className="mb-6 text-sm font-semibold text-white uppercase">
                  Contact Us
                </h2>
                <p className="mb-0 flex items-center mt-4">
                  <span>
                    <LuMapPin className="w-6 h-6 me-1" />
                  </span>
                  <div>
                    <span>Floor No.: 2nd Floor, 44A/05, SBI INTOUCH</span>{" "}
                    <br />
                    <span>Nehru Nagar, Motilal Nehru Nagar, Durg</span> <br />
                    <span>District: Durg, Chhatisgarh - 490020</span>
                  </div>
                  {/* <span>Floor No.: 2nd Floor</span> <br />
                  <span>Building No./Flat No.: 44A/05</span> <br />
                  <span>Name of Premises/Building: SBI INTOUCH</span> <br />
                  <span>Road/Street: Nehru Nagar</span> <br />
                  <span>Locality/Sub Locality: Motilal Nehru Nagar</span> <br />
                  <span>City/Town/Village: Durg</span> <br />
                  <span>District: Durg</span> <br />
                  <span>State: Chhatisgarh</span> <br />
                  <span>Pin Code: 490020</span> */}
                </p>
                <p className="mb-0 flex items-center mt-3">
                  <span>
                    <LuPhone className="w-6 h-6 me-1" />
                  </span>
                  <span>08037658894</span>
                </p>
                <p className="mb-0 flex items-center mt-3">
                  <span>
                    <LuMessageSquare className="w-6 h-6 me-1" />
                  </span>
                  <span>info@swissgold.app</span>
                </p>
              </div>
              <div className="grid sm:grid-cols-2 gap-3 sm:gap-3 md:grid-cols-3 grid-cols-1">
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase">
                    Quick Links
                  </h2>
                  <ul className="text-gray-300  font-medium">
                    <li className="mb-4">
                      <Link to="/" className="hover:underline">
                        Home
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/about" className="hover:underline">
                        About Us
                      </Link>
                    </li>
                    <li className="mb-4">
                      <a
                        href="https://swissgold.app/secure/login"
                        className="hover:underline"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                    Follow us
                  </h2>
                  <ul className="text-gray-300  font-medium">
                    <li className="mb-4">
                      <Link to="/contact-us" className="hover:underline ">
                        Contact Us
                      </Link>
                    </li>
                    <li className="mb-4">
                      <a
                        href="https://swissgold.app/secure/buyGold"
                        className="hover:underline"
                      >
                        Buy Gold
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                    Legal
                  </h2>
                  <ul className="text-gray-300  font-medium">
                    <li className="mb-4">
                      <Link to="/our-policy" className="hover:underline">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/terms-conditions" className="hover:underline">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/disclaimer" className="hover:underline">
                        Disclaimer
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-300 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between ">
          <span className="text-sm text-gray-300 sm:text-center flex items-center">
            <span className="flex me-1">
              <BiCopyright className="footer-social-icon me-1" />
              {new Date().getFullYear()}
            </span>
            <a href="#" className="hover:underline">
              SWIMMDUCK
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a
              href="#"
              className="text-gray-300 hover:text-white dark:hover:text-white"
            >
              <LuFacebook className="footer-social-icon" />
              <span className="sr-only">Facebook page</span>
            </a>

            <a
              href="#"
              className="text-gray-300 hover:text-white dark:hover:text-white ms-5"
            >
              <LuTwitter className="footer-social-icon" />
              <span className="sr-only">Twitter page</span>
            </a>
            <a
              href="#"
              className="text-gray-300 hover:text-white dark:hover:text-white ms-5"
            >
              <LuYoutube className="footer-social-icon" />
              <span className="sr-only">Youtube account</span>
            </a>
            <a
              href="#"
              className="text-gray-300 hover:text-white dark:hover:text-white ms-5"
            >
              <LuInstagram className="footer-social-icon" />
              <span className="sr-only">Instagram account</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
