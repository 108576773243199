import React from "react";

const OurPolicy = () => {
  return (
    <div className="section_padding bg-blue-50">
      <div class="">
        <h1 class="text-2xl font-bold mb-4">Refund Policy</h1>
        <p class="mb-4">
          Please note, once an order for purchase or sale has been confirmed by
          the customer, refunds or cancellation is not possible. All orders are
          final upon customer confirmation.
        </p>
        <p class="mb-4">
          Deliveries are made only to the registered address of the customer.
          Please keep proof of identity ready at the time of delivery as it may
          be required for verification by the courier partner. If no one is
          available at the registered address to take delivery, delivery will be
          attempted at least once more after which the package will be returned
          to the vault and the customer's balance will be credited back with the
          corresponding grams of gold. Shipping charges will be payable again as
          and when the customer asks for delivery.
        </p>
        <p class="mb-4">
          Customers are required to verify that the package they are accepting
          has not been tampered with and is of acceptable quality. Once delivery
          has been taken by the customer, returns and refunds are not possible.
          All of our products are 100% certified and there should never be any
          problems with quality. In the unlikely event of any issues, please
          contact{" "}
          <a href="#" class="text-blue-500">
            care@swimmduck.com
          </a>
          , we will investigate the matter to resolve any concerns or issues.
        </p>
      </div>

      <div class="mt-4">
        <h1 class="text-2xl font-bold mb-4">Grievance</h1>
        <p class="mb-4">
          SwimmDuck strongly believes in a sincere and transparent approach to
          its users. You trust and love us and we would never put growth before
          trust. We aim to minimize instances of customer complaints, grievances
          and disappointments via channelized approach, review and redressal.
          However, we recognize that there are times when our product or service
          has not been up to the mark. If you’re disappointed, then at some
          point of time we’ve not done a great enough job.
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Our users are to be treated fairly at all times.</li>
          <li>
            Complaints or concerns raised by users are dealt with an open mind,
            with courtesy and in a timely manner.
          </li>
          <li>
            Users are informed about the channels to escalate their complaints,
            concerns and grievances within SwimmDuck and their rights if they
            are not satisfied with the resolution of their complaints.
          </li>
          <li>
            SwimmDuck will take care of all complaints efficiently and fairly as
            they value each user.
          </li>
          <li>
            All employees at SwimmDuck must work in good faith and without
            prejudice to the interests of the customer.
          </li>
        </ul>
        <p class="mb-4">
          Regardless of the reason behind the customer complaint, our customers
          have complete authority to file a complaint/ share feedback if they
          are disappointed by services rendered by SwimmDuck. They can give
          their complaint/ feedback in writing, email, or on SwimmDuck.com.
        </p>
      </div>

      <div class="mt-4">
        <h1 class="text-2xl font-bold mb-4">
          Sensitizing Staff on Handling Complaints
        </h1>
        <p class="mb-4">
          Our staff undergoes regular training to ensure that customer’s queries
          and grievances are handled properly. They are encouraged to work in a
          manner which helps us in building the customer trust and confidence.
          This reflects in both the operations as well as the customer
          communications. Top reasons behind the customer queries are analyzed
          and worked upon in a way which aims at removal of these reasons from
          the root. This helps in improving the overall quality of the service
          levels gradually.
        </p>
      </div>

      <div class="mt-4">
        <h1 class="text-2xl font-bold mb-4">Customer Care Escalation Matrix</h1>
        <ul class="list-decimal list-inside mb-4">
          <li class="mb-2">
            <strong>Level 1:</strong> You can call to customer care to discuss
            any unresolved issues. If you are not satisfied with our response,
            please ask to speak with customer service supervisor and you will be
            transferred immediately or receive a call back within 1 working day.
          </li>
          <li class="mb-2">
            <strong>Level 2:</strong> You can reach us by sending an email to{" "}
            <a href="mailto:care@swimmduck.com" class="text-blue-500">
              care@swimmduck.com
            </a>
            . A ticket number will be issued to you at the time of logging your
            complaint. We will aim to respond to queries or complaints within 2
            working days.
          </li>
          <li class="mb-2">
            <strong>Level 3:</strong> In case the issue remains unresolved, you
            can write to our grievance officer,{" "}
            <a
              href="mailto:grievanceofficer@swimmduck.com"
              class="text-blue-500"
            >
              grievanceofficer@swimmduck.com
            </a>
            , mentioning the specific ticket number allotted to you at the time
            of the original complaint.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OurPolicy;
