import {
  AppInfoSection,
  BlogSection,
  ChoosingSection,
  FaqSection,
  FeaturedSection,
  Hero,
  HighlightSection,
  ImageGallery,
} from "components";

import React from "react";

const Homepage = () => {
  return (
    <>
      <Hero />
      <ChoosingSection />
      <FeaturedSection />
      <HighlightSection />
      {/* <ImageGallery /> */}
      {/* <BlogSection /> */}
      <FaqSection />
      <AppInfoSection />
    </>
  );
};

export default Homepage;
