import PageHeader from "components/website/PageHeader/PageHeader";
import React from "react";
import { BASE_URL } from "utils/Constant";

const ImageGallery = () => {
  return (
    <div>
      <PageHeader pageTitle="Image Gallery" />
      <div className="section_padding">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="grid gap-4">
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
            <div className="shadow-lg">
              <img
                class="h-auto max-w-full rounded-lg"
                src={`${BASE_URL}assets/images/no_image.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
