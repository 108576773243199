import React from "react";
import { Link } from "react-router-dom";

const CustomButton = ({ type, name, icon, link = "" }) => {
  const Icon = icon;
  return (
    <>
      {link == "" ? (
        <button className={`btn ${type}`}>
          <Icon className="btn-icon me-1" /> {name}
        </button>
      ) : (
        <Link to={link} className={`btn ${type}`}>
          <Icon className="btn-icon me-1" /> {name}
        </Link>
      )}
    </>
  );
};

export default CustomButton;
