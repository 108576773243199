import PageHeader from "components/website/PageHeader/PageHeader";
import React from "react";
import { BiMap, BiPhone } from "react-icons/bi";

const ContactUs = () => {
  return (
    <div>
      <PageHeader pageTitle="Contact Us" />

      <div className="section_padding px-12 bg-yellow-50">
        <div className="">
          <section className="">
            <div className="flex flex-wrap">
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-12/12">
                <div className="flex justify-center flex-wrap gap-x-4">
                  <div className="mb-12 shrink-0 grow-0 basis-auto md:w-[48%] w-[100%] md:px-3 lg:px-6 shadow bg-blue-950 text-white py-6 rounded-lg">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiPhone className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold ">Our Support</p>
                        <p className="text-neutral-100 ">
                          support@swissgold.app
                        </p>
                        <p className="text-neutral-100 ">
                          Toll Free: 08037658894
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 shrink-0 grow-0 basis-auto md:w-[48%] w-[100%] md:px-3 lg:px-6 shadow bg-blue-950 text-white py-6 rounded-lg">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiPhone className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Sales questions
                        </p>
                        <p className="text-neutral-100">info@swissgold.app</p>
                        <p className="text-neutral-100"></p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-12 shrink-0 grow-0 basis-auto md:w-[48%] w-[100%] md:px-3 lg:px-6 shadow bg-blue-950 text-white py-6 rounded-lg">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiMap className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold ">Address</p>
                        <p className="text-neutral-100 ">
                          <div>
                            <span>
                              Floor No.: 2nd Floor, 44A/05, SBI INTOUCH
                            </span>{" "}
                            <br />
                            <span>
                              Nehru Nagar, Motilal Nehru Nagar, Durg
                            </span>{" "}
                            <br />
                            <span>District: Durg, Chhatisgarh - 490020</span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mb-12 shrink-0 grow-0 basis-auto md:w-[48%] w-[100%] md:px-3 lg:px-6 shadow bg-blue-950 text-white py-6 rounded-lg">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiPhone className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">Press</p>
                        <p className="text-neutral-100">press@example.com</p>
                        <p className="text-neutral-100">+91-9566032225</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 shrink-0 grow-0 basis-auto md:w-[48%] w-[100%] md:px-3 lg:px-6 shadow bg-blue-950 text-white py-6 rounded-lg">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiPhone className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Bug report
                        </p>
                        <p className="text-neutral-100">bugs@example.com</p>
                        <p className="text-neutral-100">+91-9566032225</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                <div class="w-full shadow p-6 rounded bg-blue-100">
                  <form class="flex flex-col">
                    <input
                      type="text"
                      class="bg-white border-2 border-gray-300 text-gray-800 rounded-md px-3 py-3 mb-4  focus:outline-none transition ease-in-out duration-150"
                      placeholder="Full Name"
                    />
                    <input
                      type="email"
                      class="bg-white border-2 border-gray-300 text-gray-800 rounded-md px-3 py-3 mb-4  focus:outline-none transition ease-in-out duration-150"
                      placeholder="Email"
                    />
                    <input
                      type="number"
                      class="bg-white border-2 border-gray-300 text-gray-800 rounded-md px-3 py-3 mb-4  focus:outline-none transition ease-in-out duration-150"
                      placeholder="Phone Number"
                    />
                    <textarea
                      name="message"
                      class="bg-white border-2 border-gray-300 text-gray-800 rounded-md px-3 py-3 mb-4  focus:outline-none transition ease-in-out duration-150"
                      placeholder="Message"
                    ></textarea>

                    <button
                      type="submit"
                      class=" text-white font-bold py-2 px-4 rounded-md mt-4 bg-yellow-500"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
