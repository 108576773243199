import { CustomButton } from "components";
import SectionHeader from "components/website/SectionHeader/SectionHeader";
import React, { useState } from "react";
import { LuSend } from "react-icons/lu";
import ChoosingCard from "./ChoosingCard";
const ChoosingSection = () => {
  return (
    <section className="section_padding px-12 bg-yellow-50">
      <SectionHeader
        title="How it works?"
        subtitle="Choose the way you save."
      />
      <div className="grid lg:grid-cols-2 grid-cols-1 mt-10">
        <div className="flex items-center justify-center h-full lg:mb-0 mb-10">
          <img src="./assets/images/cs-1.png" className="h-[450px]" alt="" />
        </div>
        <div>
          <ChoosingCard
            title="Save via"
            subtitle="Daily Savings"
            description="Set a daily amount. Turn autosave on. Before you know, your savings will
        grow"
            link="/about"
          />
          <ChoosingCard
            title="Save via"
            subtitle="Round offs"
            description="Save as you spend. Jar takes your spare change & turns it into gold"
            link="/about"
          />
          <ChoosingCard
            title="Save via"
            subtitle="Manual savings"
            description="Set a daily amount. Turn autosave on. Before you know, your savings will
        grow"
            link="/about"
          />
        </div>
      </div>
    </section>
  );
};
export default ChoosingSection;
