import SectionHeader from "components/website/SectionHeader/SectionHeader";
import React from "react";

import FeaturedCard from "./FeaturedCard";
import {
  LuBoxes,
  LuClipboard,
  LuCreditCard,
  LuRows,
  LuSave,
  LuSendToBack,
} from "react-icons/lu";

const FeaturedSection = () => {
  const featuredItems = [
    {
      title: "Buy Gold",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      icon: LuCreditCard,
    },
    {
      title: "Sell Gold",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      icon: LuSendToBack,
    },
    {
      title: "Digital Gold",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      icon: LuClipboard,
    },
    {
      title: "Daily Savings",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      icon: LuSave,
    },
    {
      title: "Round Off",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      icon: LuRows,
    },
  ];

  return (
    <div className="section_padding bg-blue-950">
      <SectionHeader
        title="How it works?"
        subtitle="Choose the way you save."
        titleClass="text-white"
      />

      <div className="flex justify-center gap-5 flex-wrap">
        {featuredItems.map((featured, i) => {
          return (
            <FeaturedCard
              title={featured.title}
              description={featured.description}
              icon={featured.icon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedSection;
