import React from "react";
import { BiPaperPlane, BiPhone } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BASE_URL } from "utils/Constant";

const Hero = () => {
  return (
    <>
      <div className="relative">
        <img
          src={`${BASE_URL}assets/images/image_2_1.jpg`}
          alt="Image"
          className="w-full"
        />
      </div>
    </>
    // <div className="relative sm:px-12 px-8 pt-4 bg-blue-950 text-white">

    //   <div className="flex md:flex-row flex-col items-center h-full">
    //     <div className="mx-auto md:py-20 sm:py-12 py-6 lg:flex-1">
    //       <div className="sm:mb-4 sm:flex sm:justify-start">
    //         <div className="">
    //           <h6 className="mb-0">Money will make your future bright.</h6>
    //         </div>
    //       </div>
    //       <div className="text-left">
    //         <h1 className="text-2xl font-bold tracking-tight sm:text-4xl uppercase">
    //           Save Money in{" "}
    //           <span className="text-yellow-400">digital gold</span> from ₹ 10.
    //         </h1>
    //         <h2 className="text-2xl font-bold tracking-tight text-gray-300 sm:text-4xl">
    //           It’s automatic. Like magic.
    //         </h2>
    //         <div className="md:mt-10 mt-4 flex items-center justify-start gap-x-6">
    //           <Link
    //             to="https://swissgold.app/secure/buyGold"
    //             className="md:py-4 md:px-8 py-2 px-4 rounded-lg btn-primary font-semibold md:text-lg flex items-center"
    //           >
    //             <BiPaperPlane className="w-6 h-6 me-1" /> Get started
    //           </Link>
    //           <Link
    //             to="/contact-us"
    //             className="flex items-center font-semibold md:text-lg"
    //           >
    //             <BiPhone className="w-6 h-6 me-1" /> Contact Us
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="lg:flex-1 md:py-20 sm:py-10 py-4">
    //       <img
    //         src="./assets/images/hero.png"
    //         className="hero_img mx-auto"
    //         alt=""
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

export default Hero;
