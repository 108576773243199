import React from "react";

const MissionAndKeyFeatures = ({ title, text, icon = "" }) => {
  let Icon = icon;
  return (
    <div className="relative pl-16 bg-blue-950 shadow-lg p-4 rounded-lg">
      <div className="text-base font-semibold leading-7 text-gray-50">
        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500">
          <Icon className="h-6 w-6 text-white" />
        </div>
        {title}
      </div>
      <dd className="mt-2 text-base leading-7 text-gray-100">{text}</dd>
    </div>
  );
};

export default MissionAndKeyFeatures;
