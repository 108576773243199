import React from "react";

const TermsConditions = () => {
  return (
    <div className="section_padding bg-blue-50">
      <h1 class="text-xl font-bold mb-4">Terms &amp; Conditions</h1>
      <p class="mb-4">
        These terms of use (“Terms and Conditions”) mandate the terms on which
        the users (“You” or “Your” or “User”) can access and register on the
        website{" "}
        <a href="https://www.swimmduck.com" class="text-blue-500 underline">
          www.swimmduck.com
        </a>{" "}
        including its mobile application 'swissgold.app’ operated and managed by
        Swimmduck Private Limited (“Company” or “We” or “Us”) a company duly
        incorporated under the laws of India, having corporate identification
        number U62099CT2024PTC015864, collectively referred to as, the
        “Platform”.
      </p>
      <p class="mb-4">
        <strong>
          IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS AND THE TERMS AND
          CONDITIONS OF AUGMONT (AS AVAILABLE AT{" "}
          <a
            href="https://www.augmont.com/terms-conditions"
            class="text-blue-500 underline"
          >
            https://www.augmont.com/terms-conditions
          </a>
          ), YOU MAY NOT USE THE PLATFORM AND SHOULD NOT PROCEED TO CREATE ANY
          ACCOUNTS OR ACQUIRE ANY SUBSCRIPTIONS TO THE PLATFORM. BY USING THE
          PLATFORM, YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND CONDITIONS,
          INCLUDING ALL AMENDMENTS MADE HERETO FROM TIME TO TIME.
        </strong>
      </p>
      <p class="mb-4">
        Swimmduck may revise these Terms and Conditions of Access and Use and
        Specific Terms from time to time and at any time, without notice to you.
        If you do not agree to be bound by these Terms and Conditions of Access
        and Use, as modified from time to time, you should leave the Platform
        immediately. The Terms and Conditions of Access and Use and Specific
        Terms shall be hereinafter collectively referred to as “Terms and
        Conditions”.
      </p>
      <p class="mb-4">
        This is an electronic record in terms of the Information Technology Act,
        2022 and the rule made there under, and does not require any physical or
        digital signatures. This is being published in accordance with Rule 3(1)
        of the Information Technology (Intermediaries Guidelines) Rules, 2011.
      </p>
      <p class="mb-4">
        The Platform is an online portal that facilitates the Users to
        purchase/sale/transfer gold in digital form from a brand named ‘Augmont
        operated and managed by Augmont Goldtech Private Limited, a company
        incorporated under the laws of India with corporate identification
        number U51909MH2020PTC337639, and Augmont provides for purchase and sale
        of Gold, and providing services of safe keeping, vaulting, delivery and
        fulfillment of gold and related services (“Services”).
      </p>
      <p class="mb-4">
        <strong>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT COMPANY IS ONLY A DISTRIBUTOR
          THAT FACILITATES USERS TO BUY GOLD FROM AUGMONT. YOU UNDERSTAND THAT
          BY REGISTERING TO THE PLATFORM AND/OR ACCESSING SERVICES DIRECTLY OR
          INDIRECTLY FROM THE PLATFORM, YOU ONLY RECEIVE THE ABILITY TO USE THE
          PLATFORM FOR PURCHASE OF GOLD FROM AUGMONT OR SUCH OTHER PRODUCTS
          OFFERED BY US FROM TIME TO TIME. WE SHALL NOT INCUR ANY LIABILITY IN
          RELATION TO ANY DISPUTE RELATING THAT ARISES IN RELATION TO PURCHASE
          OF GOLD OR SERVICES OFFERED BY AUGMONT. YOU EXPRESSLY AGREE THAT WE
          ARE NOT RESPONSIBLE FOR CUSTODY, QUANTITY AND QUALITY OF PHYSICAL GOLD
          THAT YOU PURCHASE THROUGH THE PLATFORM AND HENCE WE SHALL NOT BE
          LIABLE IN CASE OF ANY DISPUTE THAT ARISES IN RELATION TO CUSTODY OF
          PHYSICAL GOLD WITH THE CUSTODIAN AND TRUSTEESHIP WITH THE TRUSTEE
          ADMINISTRATOR.
        </strong>
      </p>
      <p class="mb-4">
        You understand that the Gold is being offered for purchase by Augmont,
        and the User can also sell the Gold through Augmont. As per the terms
        and conditions of Augmont (available at{" "}
        <a
          href="https://www.augmont.com/terms-and-conditions"
          class="text-blue-500 underline"
        >
          https://www.augmont.com/terms-and-conditions
        </a>
        ), the Gold purchased by You will be accounted in a customer account
        maintained by Augmont and the corresponding quantity of gold in physical
        form will be storied In Vault (“Custodian”). In order to safeguard your
        interest, Augmont has also appointed a trustee (“Trustee
        Administrator”), and you agree to the appointment of such Trustee
        Administrator and the Custodian, and also accede to the terms for such
        arrangement with the Trustee Administrator (being the Trustee
        Administrator agreement). The Trustee Administrator shall be responsible
        for monitoring the physical gold held on your behalf with the Custodian.
      </p>
      <p class="mb-4">
        Please read these Terms and Conditions and privacy policy (available at{" "}
        <a
          href="https://www.swimmduck.com/term-and-conditions"
          class="text-blue-500 underline"
        >
          https://www.swimmduck.com/term-and-conditions
        </a>
        ) carefully before registering on the Platform or accessing any material
        and/or information through the Platform. The Company retains an
        unconditional right to modify or amend this Terms and Conditions without
        any requirement to notify you of the same. You can determine when this
        Terms and Conditions were last modified by referring to the ‘Last
        Updated’ legend above. It shall be your responsibility to check this
        Terms and Conditions periodically for changes. Your continued use of the
        Platform shall indicate an acceptance of the amended Terms and
        Conditions, and shall signify your consent to the changes to the Terms
        and Conditions and the intention to be legally bound by the amended
        Terms and Conditions.
      </p>
      <p class="mb-4">
        By using the Services, you acknowledge that Company is not responsible
        for the quality of physical gold being offered and sold by Augmont
        Goldtech Private Limited and that any disputes in relation to the
        quality of physical gold shall be raised directly by you to Augmont
        Goldtech Private Limited.
      </p>
      <h2 class="text-xl font-bold mb-4">Eligibility</h2>
      <p class="mb-4">
        By using the Services, you represent and warrant that you are above the
        18 years of age and qualified to enter into a legally binding contract,
        enforceable under Indian law, including the Indian Contract Act, 1872.
        If you are not eligible to enter into any contract, then please
        discontinue use of the Platform and leave now. If you are the parent or
        guardian of a child under 18 years of age, by registering/by creating an
        account or profile on the Platform, you provide your consent to your
        child’s registration and you agree to be bound by these Terms and
        Conditions in respect of their use of our Platform. We will at all times
        assume (and by using this Platform you warrant that) you have the legal
        ability to purchase and sale Gold and otherwise use this Platform, and
        capable of being bound by these Terms and Conditions. Further, by using
        the Platform, You confirm that you are not registered under any
        applicable Goods and Services Tax Act, 2017 and that if, in the course
        of your relationship with our Platform and/or Augmont, you become
        registered under the applicable Goods and Services Tax Act, 2017, you
        shall inform Augmont of your change in status immediately, and no later
        than 24 hours of such change, and provide any relevant information and
        documents, as may be requested by Augmont. The Company shall not be
        liable for any actions that may be undertaken by Augmont pursuant to
        change of your status on registration under the applicable Goods and
        Services Tax Act, 2017. If necessary, you shall seek independent tax
        advice, and these Terms and Conditions do not constitute any tax advice
        to you.
      </p>
      <h2 class="text-xl font-bold mb-4">Registration</h2>
      <p class="mb-4">
        The Services are available to only those who have subscribed to the
        Platform by registering and creating account with Us. You can register
        to the Platform by providing your personal information including name,
        address, state, pin code and registered mobile number. The registration
        will be validated by sending one time password (OTP) to registered
        mobile number.
      </p>
      <p class="mb-4">
        We may also ask You for certain financial information, including Your
        billing address, bank account details, credit card number, expiration
        date and/or other payment related details or other payment method data,
        and debit instructions or other standing instructions to process
        payments for the Platform Services (as defined below). The Company may
        also ask you to provide certain additional information about yourself on
        a case-to-case basis.
      </p>
      <p class="mb-4">
        If there is any change in the account information, You shall promptly
        update Your account information on the Platform. If You provide any
        information that is untrue, inaccurate, not correct or incomplete (or
        becomes untrue, inaccurate, not correct or incomplete), or if the
        Company has reasonable grounds to suspect that such information is
        untrue, inaccurate, not correct or incomplete, the Company has the right
        to suspend or terminate Your Account and refuse any and all correct or
        future use of the Platform (or any portion thereof) at its sole and
        absolute discretion, in addition to any right that the Company may have
        against You at law or in equity, for any misrepresentation of
        information provided by You.
      </p>
      <p class="mb-4">
        By registering on the Platform, you agree to (a) provide correct details
        when prompted on the Platform and failure to do so may invalidate your
        request to use Services; (b) authorize the Platform and the Company to
        retain the information shared by You for the purpose of using the
        Platform and/or accessing the Services and for any marketing campaign
        undertaken by the Company and/or third-party service providers; and (c)
        by giving Your details, You agree to abide by the Terms and Conditions
        and the privacy policy. Also, by registering, you agree that you will
        not allow others to use Your account and that you are fully responsible
        for all activities that occur under Your username. We may assume that
        any communications we receive under Your account have been made by You.
      </p>
      <p class="mb-4">
        By providing such personal information, You consent to receive all
        information, communication and instructions relating to the Platform,
        the Services offered by the Platform and/or third-party service
        providers, and for availing special offers and promotional benefits. You
        shall solely be responsible for the appropriate use of the same. You
        shall immediately notify the Company of any unauthorized use / breach of
        your password or account and ensure that You exit from Your account at
        the end of each session. The Company reserves the right to refuse
        service, terminate accounts, remove or edit content, or cancel orders in
        their sole and absolute discretion. The Company cannot and will not be
        liable for any loss or damage arising from Your failure to comply with
        these Terms and Conditions. You may be held liable for losses incurred
        by the Company or any other user of or visitor to the Platform due to
        authorized or unauthorized use of Your Account as a result of Your
        failure in keeping Your Account information secure and confidential.
      </p>
      <h2 class="text-xl font-bold mb-4">Platform Services</h2>
      <p class="mb-4">
        By registering on the Platform, You are allowed to purchase Gold
        pursuant to the collaboration between the Company and Augmont. The
        Company hereby grants You, a limited, non-exclusive, non-transferable,
        royalty free license to use the Platform for the purposes of availing
        the Services. The services offered on the Platform shall include, but is
        not limited to the following: (a) facilitating buying and selling of
        Gold; and (b) maintaining a record of Your personal information and
        financial transactions in a secure and confidential manner;. Upon no
        User transaction being detected, Our system intelligently updates the
        User ledger to meet the User’s savings goal of up to Rs. 10 per day
        (“Platform Services”).
      </p>
      <p class="mb-4">
        However, before placing the order and availing the Services, the User
        may be required to provide certain KYC documentation and other
        information as may be required by Augmont in such form and manner as it
        may deem fit. This information may be requested from You at the time of
        registration, or at a later stage, including the following events, to
        prevent fraudulent activities:{" "}
      </p>
      <ul class="list-disc list-inside mb-4">
        <li>You withdraw your purchase of Gold;</li>
        <li>You wish to get Your Customer Gold delivered to Your doorstep.</li>
      </ul>
      <p class="mb-4">
        The KYC verification shall be undertaken based on the PAN Card issued to
        You. You shall provider of your PAN Card when it is required (investment
        exceed 1.8 lakh). We shall verify your details from the database of the
        issuing authority, including through Digilocker. If you do not have a
        PAN Card, you may submit Form 60 to us.
      </p>
      <p class="mb-4">
        It is hereby clarified that any Services offered (purchase, sale or
        transfer of Gold on Augmont platform) are governed under specific terms
        and conditions of Augmont, operated and managed by Augmont Goldtech
        Private Limited which can be accessed at{" "}
        <a
          href="https://www.augmont.com/terms-and-conditions"
          class="text-blue-500 underline"
        >
          https://www.augmont.com/terms-and-conditions
        </a>
        .
      </p>

      <p class="mb-4">
        It is also clarified that the Company is merely providing a platform to
        its Users to facilitate the transaction of sale and investment of Gold
        and will not be liable in any manner with respect to the products and/or
        services offered to You by Augmont. You hereby agree and acknowledge
        that the data and information provided on the Platform does not
        constitute advice of any nature whatsoever and shall not be relied upon
        by You while making investment decisions and You shall be solely
        responsible for any investment decisions and for the purchase of any
        Gold on the Platform. In no event shall the Company be liable to You for
        any loss or damage that may cause or arise from or in relation to these
        Terms and Conditions and/or due to use of this Platform or due to
        investments made using this Platform. As part of the Platform Services
        provided to You, on availing such services, You agree to provide honest
        feedback/review about the concerned Platform Service, if required or
        requested by the Company from time to time.
      </p>

      <h2 class="text-xl font-bold mb-4">
        Use of the Platform and availing the Services
      </h2>
      <p class="mb-4">
        You acknowledge that the Services are for Your personal use and agree
        not to publish the Gold prices or descriptions of Gold and/or any other
        information displayed on the Platform (in lieu of Your access to the
        Platform) on any other medium. You agree to use the Platform only: (a)
        for purposes that are permitted by the Terms and Conditions; and (b) in
        accordance with any applicable law, regulation or generally accepted
        practices or guidelines.
      </p>
      <p class="mb-4">
        You agree that You shall not copy, reproduce, sell, redistribute,
        publish, enter into a database, display, perform, modify, alter,
        transmit, license, create derivatives from, transfer or in any way
        exploit any part of any information, content, materials, services
        available from or through the Platform, except that You may download the
        Platform for Your own personal, internal and non-commercial use. You
        agree that You will not use the Platform in any manner or engage in any
        activity that may damage, disable or impair or adversely affect the use
        of the Platform or interfere with any other users’ use, legal rights, or
        enjoyment of the Platform. Further, You agree not to remove any text,
        copyright or other proprietary notices contained in the content
        downloaded from the Platform.
      </p>

      <p class="mb-4">
        Further, while using the Platform, You undertake not to:
      </p>
      <ul class="list-disc list-inside mb-4">
        <li>
          Defame abuse, harass, threaten or otherwise violate the legal rights
          of others;
        </li>
        <li>
          Publish post, upload, distribute or disseminate any inappropriate,
          profane, defamatory, infringing, obscene, indecent or unlawful topic,
          name, material or information;
        </li>
        <li>
          Copy, republish, post, display, translate, transmit, reproduce or
          distribute any content on the Platform through any medium without
          obtaining the necessary authorization from the Company in writing;
        </li>
        <li>
          Engage in any activity that interferes with or disrupts access to the
          Platform (or the servers and networks which are connected to the
          Platform);
        </li>
        <li>
          Attempt to gain unauthorized access to any portion or feature of the
          Platform, any other systems or networks connected to the Platform, to
          any of the Company’s server, or through the Platform, by hacking,
          password mining or any other illegitimate means;
        </li>
        <li>
          Probe, scan or test the vulnerability of the Platform or any network
          connected to the Platform, nor breach the security or authentication
          measures on the Platform or any network connected to the Platform;
        </li>
        <li>
          Reverse look-up, trace or seek to trace any information on any other
          user, of or visitor to, the Platform, to its source, or exploit the
          Platform or information made available or offered by or through the
          Platform, in any way whether or not the purpose is to reveal any
          information, including but not limited to personal identification
          information, other than Your own information, as provided on the
          Platform;
        </li>
        <li>
          Disrupt or interfere with the security of, or otherwise cause harm to,
          the Platform, systems resources, accounts, passwords, servers or
          networks connected to or accessible through the Platform or any
          affiliated or linked sites;
        </li>
        <li>
          Collect or store data about other users in connection with the
          prohibited conduct and activities;
        </li>
        <li>
          Use any device or software to interfere or attempt to interfere with
          the proper working of the Platform or any transaction being conducted
          on the Platform, or with any other person’s use of the Platform;
        </li>
        <li>
          Use the Platform or any material or content for any purpose that is
          unlawful or prohibited by these Terms and Conditions, or to solicit
          the performance of any illegal activity or other activity which
          infringes the rights of the Company or other third parties;
        </li>
        <li>
          Falsify or delete any author attributions, legal or other proper
          notices or proprietary designations or labels of the origin or source
          of software or other material contained in a file that is uploaded;
        </li>
        <li>
          Violate any applicable laws or regulations for the time being in force
          within or outside India or anyone’s right to privacy or personality;
        </li>
        <li>
          Violate the Terms and Conditions contained herein or terms and
          conditions provided elsewhere; and
        </li>
        <li>
          Reverse engineer, modify, copy, distribute, transmit, display,
          perform, reproduce, publish, license, create derivative works from,
          transfer, or sell any information or software obtained from the
          Platform.
        </li>
      </ul>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Placing of Order</h2>
        <p class="mb-2">
          You can purchase Gold on the Platform on a pre-payment basis i.e.,
          once you place the order and confirms the transaction for purchase of
          Gold, and agree to the Terms and Conditions (“Customer Order”), the
          Platform will notify Augmont.
        </p>
        <p class="mb-2">
          Once the payments are received by Augmont and the KYC information is
          found acceptable, Augmont shall issue an invoice to You confirming the
          Customer Order placed within a period of 3 (three) business days of
          placing such order, in a manner it may deem fit. Notwithstanding
          anything to the contrary contained in these Terms and Conditions,
          Company and/or Augmont shall be entitled to accept or reject the User,
          for any reason whatsoever, at its sole and exclusive discretion.
        </p>
        <p class="mb-2">
          Once the Customer Order is placed, You are not entitled to cancel such
          a Customer Order, provided however that such Customer Order shall
          stand cancelled if the payment fails for any reason whatsoever. The
          Platform and/or Augmont reserves the right to cancel the Customer
          Order, at its sole discretion, if Your information, provided prior to
          placing the Customer Order, is not found acceptable and the Platform
          and/or Augmont are of the opinion that You are not eligible to
          purchase Gold from Augmont.
        </p>
        <p class="mb-2">
          The quantity of Gold purchased by You shall be stored with the
          Custodian in a vault on Your behalf. You hereby authorize Augmont to
          store such physical gold products purchased by You pursuant to the
          Customer Order, including, but not limited to, bullion, coins or
          jewellery (as the case may be) in the secured vault maintained by the
          Custodian on Your behalf (“Customer Gold”). It is hereby clarified
          that Your purchase of the physical gold corresponding to the Your
          Customer Order shall be deemed to be completed and title in relation
          thereto shall be deemed to have been passed to You upon such relevant
          portion of the Customer Gold being stored in the vault with the
          Custodian on Your behalf in accordance with the terms and conditions
          of Augmont, subject to applicable laws.
        </p>
        <p class="mb-2">
          You will be provided with free storage for your Customer Gold for 5
          years, or for such period as more particularly stipulated by Augmont
          in this regard from time to time at its sole discretion and notified
          to Customers on the Platform (“Free Storage Period”). After the expiry
          of the Free Storage Period, Augmont shall be entitled to levy storage
          charges for such Customer Gold at such rate as would be specified on
          the Platform, and which may be revised from time to time. The charges
          would be levied by deducting the gold balance at the end of each month
          by a percentage amount at the specified rate. You are advised to
          periodically check the Platform to understand these storage charges.
          In the event, Augmont is not able to deduct the storage charges
          because Your gold balance is too low, then Augmont shall be entitled
          to sell such portion of Your Customer Gold stored with the Custodian
          that is necessary or required to recover the unpaid storage charges in
          question.
        </p>
        <p class="mb-2">
          To ensure that the Customer Gold stored in such vault is adequately
          protected, necessary insurance policy/ies has been obtained by the
          Custodian, with the cost of the insurance and the insurance premia is
          borne by the Custodian. Pursuant to such insurance policy/ies, for any
          loss or damage to the Customer Gold stored in the vault, You authorize
          the Trustee Administrator to act as Your beneficiary under the
          insurance policies and to take all steps necessary to protect Your
          interests.
        </p>
        <p class="mb-2">
          While the Custodian has taken the necessary insurance policies, in
          case of occurrence of an event not covered in such insurance policies,
          the Customer Gold may be at risk. The insurance policies obtained by
          the Custodian are in line with the global industry practices and cover
          losses due to fire, lightning, theft, cyclone, earthquake, flood, etc.
          but do not cover losses due to events such as war, revolution,
          derelict weapons of war, nuclear radiation, etc.
        </p>
        <p class="mb-2">
          In the event of any expenses or charges remaining payable to the
          Custodian, the Trustee Administrator or such other person appointed by
          Augmont as an intermediary, or otherwise till actual delivery or
          fulfillment of any of Your Customer Orders for any reason, including
          where Augmont is unable to service such requests due to any event of
          default, thereby adversely effecting or jeopardizing the fulfillment
          of Your Customer Orders, then the Trustee Administrator will be
          entitled to sell part of the Customer Gold and satisfy such
          outstanding expenses or charges as required in accordance with these
          Terms and Conditions read with the Trustee Administrator agreement.
          The sums due to You and/or gold to be delivered (as the case may be),
          after settling the aforementioned charges, would be dealt with in
          accordance with these Terms and Conditions read with the Trustee
          Administrator agreement.
        </p>
        <ul class="list-disc pl-6 mb-2">
          <li>
            Except where noted otherwise, the price displayed on the Platform,
            represents the market linked price of Gold as indicated by Augmont.
            Market linked prices means that these quotes are linked to the
            prices of gold in the commercial bullion market in India. However,
            it does not indicate that the gold prices displayed on the Platform
            will always be same as that of the prevalent prices displayed in
            other commercial bullion market. As a purchaser, it is your
            responsibility to compare the prices and take appropriate decisions
            after adequate diligence
          </li>
          <li>
            Notwithstanding the foregoing, you understand that these prices may
            vary multiple times within a day, and accordingly Your payment
            obligations for any Customer Order will depend on the prices
            displayed at the relevant time.
          </li>
          <li>
            Payment policy: Payment will be accepted through the payment options
            made available on the Platform, which may include redirection to a
            payment gateways and aggregators hosted by third-party website or
            applications including UPI payments At the time of purchase,
            fulfillment sale and/or transfer of Gold, the relevant taxes will be
            chargeable as applicable as per the Government regulations.
          </li>
          <li>
            However, please note that the payment terms, delivery, warranty and
            cancellation and refund policy are subject to the terms and
            conditions of Augmont which can be accessed at{" "}
            <a
              href="https://www.augmont.com/terms-and-conditions"
              class="text-blue-500 underline"
            >
              https://www.augmont.com/terms-and-conditions
            </a>
            .
          </li>
          <li>
            The transactions on the Platform with will be completed only after
            successful transfer of money to Augmont’s bank account, either from
            Your registered bank account or from the escrow account maintained
            by the Company. Please note that the corresponding gold quantity
            will be credited to Your Account maintained with Augmont, within 5
            (five) business days from the date of successful transfer of such
            amount. Please look at Augmont Terms and conditions accessed at{" "}
            <a
              href="https://www.augmont.com/terms-and-conditions"
              class="text-blue-500 underline"
            >
              https://www.augmont.com/terms-and-conditions
            </a>{" "}
            and understand fully the terms of custodian and trusteeship
            arrangement before making the investment.
          </li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Delivery of Gold</h2>
        <ol class="list-decimal pl-6 mb-2">
          <li class="mb-2">
            For the purposes of making delivery to You, You are required to
            provide a valid address and/or any other
            documents/information/biometric identification that may be specified
            in this regard by Company and Augmont on the Platform from time to
            time.
          </li>
          <li class="mb-2">
            You are required to take delivery of Your Customer Gold within such
            maximum period as shall be specified for this purpose by Augmont
            from time to time on the Platform (“Maximum Storage Period”).
          </li>
          <li class="mb-2">
            In the event that no valid address has been provided by You during
            the Maximum Storage Period, then Platform and/ Augmont shall for a
            period 1 (one) year commencing from the date of expiry of Maximum
            Storage Period (such period being the “Grace Period”) attempt at
            least once to contact You using the contact information provided by
            You to obtain either (i) an address to which You would require the
            gold in question to be delivered or (ii) Your bank account details
            into which sale proceeds of the Customer Gold shall be deposited. In
            the event that Platform and/or Augmont has not been able to contact
            You during the applicable Grace Period using the contact information
            provided by You or where You shall during the Grace Period either
            fail to take delivery of the physical gold in question for any
            reason whosoever (including where You shall not have provided any
            address to take delivery of such physical gold); or provide the
            details for a valid bank account into which the proceeds of any sale
            of such Customer Gold is to be deposited; then upon expiry of the
            applicable Grace Period for the Customer Gold in question, Augmont
            shall be entitled to purchase such Customer Gold from You for cash
            at prevailing market prices and the Trustee Administrator shall
            deposit the proceeds therefrom into a bank account operated by the
            Trustee Administrator for and on Your behalf and provide to Augmont
            such details and/or access to such bank account (as necessary) to
            enable You to claim such sale proceeds from the Trustee
            Administrator. If such sale proceeds remain unclaimed by You for a
            period of 3 (three) years (from the date of expiry of the Grace
            Period), then the Trustee Administrator shall transfer such proceeds
            to the Prime Minister’s Relief Fund (PMRF) or such other fund as may
            be identified for this purpose.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Refund and Return Policy</h2>
        <ol class="list-decimal pl-6 mb-2">
          <li class="mb-2">
            No order can be cancelled or refunded post confirmation of the
            transaction and issuance of the invoice by Augmont. All deliveries
            will be made only to the registered address and verification will be
            required at the time of delivery.
          </li>
          <li class="mb-2">
            In case the delivery is not successful, the package will be returned
            to the vault and the corresponding gold grams shall be credited back
            to the customer’s account. The customer will have to bear the
            shipping charges for redelivery in such case.
          </li>
          <li class="mb-2">
            You should inspect the package for any damage or tamper before
            receiving or signing for receipt. If damaged or tampered, do not
            accept the package and inform support team immediately. Returns and
            refunds are not possible once delivery is accepted. For any quality
            issues, contact support within 24 hours of delivery.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Your Obligations</h2>
        <ul class="list-disc pl-6 mb-2">
          <li class="mb-2">
            You agree to comply with these Terms and Conditions and all posted
            Platform instructions.
          </li>
          <li class="mb-2">
            You will not alter or modify the Platform or the Content.
          </li>
          <li class="mb-2">
            You agree to make payments from Your own legitimate sources through
            the approved banking channels.
          </li>
          <li class="mb-2">
            You agree to seek independent advice prior to investing.
          </li>
          <li class="mb-2">
            You agree not to transfer Your registered account to another party.
          </li>
          <li class="mb-2">
            You agree not to impersonate any person or misrepresent Your
            identity.
          </li>
          <li class="mb-2">
            You are responsible for any act of omission or commission including
            entering UPI details, phone numbers, email addresses, bank details
            and addresses.
          </li>
          <li class="mb-2">
            You are liable for any loss or damage resulting from Your default
            under these Terms and Conditions.
          </li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Limitation of Liability</h2>
        <p class="mb-2">
          The Platform and the Content are provided "as is" without warranties
          of any kind. The Company does not guarantee the accuracy,
          completeness, or usefulness of any information provided on the
          Platform. Your use of the Platform is at Your sole risk.
        </p>
        <p class="mb-2">
          The Company shall not be liable for any loss or damage arising from
          Your use of the Platform or reliance on the Content provided on the
          Platform.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Indemnity</h2>
        <p class="mb-2">
          You agree to indemnify and hold harmless the Company, its affiliates,
          directors, officers, employees, and agents from any loss, damage,
          claim, or demand arising out of Your breach of these Terms and
          Conditions or Your use of the Platform.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">
          Governing Law and Dispute Resolution
        </h2>
        <p class="mb-2">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of India. Any disputes arising out of or in
          connection with these Terms and Conditions shall be resolved through
          arbitration in accordance with the Indian Arbitration and Conciliation
          Act of 1996. The place of arbitration shall be Raipur, India, and the
          language of arbitration shall be English.
        </p>
        <p class="mb-2">
          The courts in Raipur, India shall have exclusive jurisdiction over any
          disputes arising out of these Terms and Conditions.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Communications</h2>
        <p class="mb-2">
          You agree to receive communications from the Platform via SMS, email,
          or WhatsApp regarding the Platform Services. You may unsubscribe from
          email communications by writing to info@swimmduck.com.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-semibold mb-2">Grievances</h2>
        <p class="mb-2">
          In case of any grievances, you may contact the Grievance Officer at
          grievance-officer@swimmduck.com. The Grievance Officer shall
          acknowledge and respond to your complaint within 48 hours.
        </p>
        <p class="mb-2">
          If you are not satisfied with the resolution provided by the Grievance
          Officer, you may escalate the matter to the Consumer Dispute Redressal
          Forum established under the Consumer Protection Act, 2019.
        </p>
      </section>
    </div>
  );
};

export default TermsConditions;
