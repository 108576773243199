import React from "react";

const PageHeader = ({ pageTitle }) => {
  return (
    <div className="py-8 px-12 bg-slate-900 text-white">
      <div className="text-center">
        <h2 className="font-bold mb-0 text-2xl uppercase">{pageTitle}</h2>
      </div>
    </div>
  );
};

export default PageHeader;
