import { CompanyGlance, ManagingDirector, Teams } from "components";
import AboutApp from "components/website/AboutUs/AboutApp";
import MissionAndKeyFeatures from "components/website/AboutUs/MissionAndKeyFeatures";
import OurMission from "components/website/AboutUs/OurMission";
import OurVision from "components/website/AboutUs/OurVision";
import PageHeader from "components/website/PageHeader/PageHeader";
import React from "react";

const About = () => {
  return (
    <>
      <PageHeader pageTitle="About Us" />
      <CompanyGlance />
      <AboutApp />
      {/* <ManagingDirector />
      <Teams /> */}
      <MissionAndKeyFeatures />
      <OurVision />
      <OurMission />
    </>
  );
};

export default About;
