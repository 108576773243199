import React from "react";
import MissionAndKeyFeaturesCard from "./MissionAndKeyFeaturesCard";
import {
  BiMemoryCard,
  BiMoney,
  BiSolidHand,
  BiSync,
  BiUser,
} from "react-icons/bi";

const MissionAndKeyFeatures = () => {
  return (
    <>
      <div className="bg-blue-50 py-12 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 mb-0 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              MISSION STATEMENT & KEY FEATURES
            </p>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              To democratize gold investment, providing a secure and convenient
              platform for individuals to buy, sell, and store gold digitally
            </p>
          </div>

          <div className="mx-auto mt-12 max-w-2xl lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-10">
              <MissionAndKeyFeaturesCard
                title="Real-Time Transactions"
                text="Users can buy and sell gold at real-time market prices."
                icon={BiSync}
              />
              <MissionAndKeyFeaturesCard
                title="Secure Storage"
                text="Gold is stored in insured vaults with high-security measures."
                icon={BiMemoryCard}
              />
              <MissionAndKeyFeaturesCard
                title="Transparency"
                text="Transparent pricing with no hidden fees."
                icon={BiMoney}
              />
              <MissionAndKeyFeaturesCard
                title="User-Friendly Interface"
                text="Easy-to-navigate app with a seamless user experience"
                icon={BiUser}
              />
              <MissionAndKeyFeaturesCard
                title="Accessibility"
                text="Allows small and large investments, making gold accessible to everyone."
                icon={BiSolidHand}
              />
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionAndKeyFeatures;
