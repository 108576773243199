import React from "react";

const HighlightSection = () => {
  return (
    <div className="section_padding bg-blue-100">
      <div className="text-center shadow-xl sm:px-10 sm:py-20 px-4 py-4 bg-white rounded-3xl">
        <h5 className="text-blue-800 text-xl font-bold">Digital gold?</h5>

        <h1 className="lg:text-4xl md:text-3xl sm:text-2xl text-xl font-semibold w-[80%] mx-auto leading-normal">
          With the <span className="text-blue-800">SWISSGOLD</span> app, you can
          participate in the tradition of saving in{" "}
          <span className="text-yellow-500">24 Karat gold</span> at{" "}
          <span className="text-green-800">99.5% purity</span>, and build wealth
          and security for you and your family.
        </h1>
      </div>
    </div>
  );
};

export default HighlightSection;
