import React from "react";

const Disclaimer = () => {
  return (
    <div className="section_padding bg-blue-50">
      <h1 class="text-2xl font-bold mb-4">Disclaimer</h1>
      <p>No content available, we will update soon.</p>
    </div>
  );
};

export default Disclaimer;
