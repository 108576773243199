import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import SectionHeader from "components/website/SectionHeader/SectionHeader";
const text = `
SWISSGOLD App is 100% safe and secure to use for your Daily Savings & Investments in Gold. It is powered by Augmont and all payments happen over secure banking networks.
`;
const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Is SWISSGOLD App safe to use?",
    children: <p>{text}</p>,
    style: panelStyle,
  },
  {
    key: "2",
    label: "Can I Earn Money from the SWISSGOLD App?",
    children: <p>{text}</p>,
    style: panelStyle,
  },
  {
    key: "3",
    label: "What does the SWISSGOLD App do?",
    children: <p>{text}</p>,
    style: panelStyle,
  },
  {
    key: "4",
    label: "How do I download the SWISSGOLD App?",
    children: <p>{text}</p>,
    style: panelStyle,
  },
];
const FaqSection = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: "17px",
    background: "rgb(23 37 84)",
    padding: "12px 14px",
    borderRadius: "19px",
    color: "white !important",
    fontSize: "18px",
    fontWeight: "600",
  };
  return (
    <div className="section_padding bg-yellow-50">
      <div className="md:w-[80%] w-[95%] mx-auto faq_h_section">
        <SectionHeader title="FAQ" subtitle="All your questions, answered." />

        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{ color: " white" }}
          items={getItems(panelStyle)}
        />
      </div>
    </div>
  );
};
export default FaqSection;
